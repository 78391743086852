import './App.css';
import logo from './images/limak.png';

function App() {
  return (
    <div className="container">
      <img src={logo} alt="Limak Logo" width={270} className="logo" />
      <div>
        <a href='https://id.limakhotels.com/#/login?registerType=1DA31021-9A24-4C1C-B7C0-C8CF148C5F36&t=1'>
          <button className="button">Sarf / Genel Malzeme Tedarikçisi Girişi</button>
        </a>
      </div>
      <div>
        <a href='https://id.limakhotels.com/#/login?registerType=1DA31022-9A24-4C1C-B7C0-C8CF148C5F37&t=1'>
          <button className="button">Gıda Tedarikçisi Girişi</button>
        </a>
      </div>
      <div>
        <a href='https://id.limakhotels.com/#/login?registerType=1DA31023-9A24-4C1C-B7C0-C8CF148C5F38&t=1'>
          <button className="button">Teknik Malzeme Tedarikçisi Girişi</button>
        </a>
      </div>
      <div>
        <a href='https://id.limakhotels.com/#/login?registerType=1DA31024-9A24-4C1C-B7C0-C8CF148C5F38&t=1'>
          <button className="button">Hizmet Tedarikçisi Girişi</button>
        </a>
      </div>
    </div>
  );
}

export default App;
